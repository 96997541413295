import { isBrowser } from "./ssr";

const isAgent = (name: RegExp) => {
  if (isBrowser()) {
    return window.navigator.userAgent.search(name) !== -1;
  }
};

export const isIOS = isAgent(/iphone/gi) || isAgent(/ipad/gi);

export const isAndroid = isAgent(/android/gi);
