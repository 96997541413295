/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { isBrowser } from "../../../utils/ssr";
import { DDIcon } from "./DDIcon";
import { isAndroid, isIOS } from "../../../utils/device";
import { useOnboardingTranslations } from "../translations";
import { Link } from "../../../components/Link/Link";
import { AppPage } from "../../../pages";

// ts-prune-ignore-next
export default function OnboardingSuccess() {
  const tt = useOnboardingTranslations();
  if (!isBrowser()) {
    return null;
  }

  const imageData = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "welcome/welcome-banner-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 700)
        }
      }

      mobile: file(relativePath: { eq: "welcome/welcome-banner-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 750)
        }
      }
    }
  `);

  return (
    <section className="w-full gradient bg-gradient-to-b from-blue-200 to-white">
      <div className="max-w-screen-xl mx-auto">
        <div className="grid grid-flow-row md:grid-flow-col md:grid-cols-2 md:py-28 gap-y-8  min-h-screen-without-header">
          <div className="pl-5 pr-5 xl:pl-0 xl:pr-8 flex flex-col items-center md:items-start">
            <div className="block md:hidden pt-14 pb-12">
              <DDIcon className="block" />
            </div>
            <h1 className="display-xxxs md:display-s lg:display-m text-center md:text-left text-blue-600 mt-0 md:mt-12 mb-0">
              {tt.onBoardingSuccess.heading}
            </h1>
            <p className="text-center md:text-left body-m md:body-xl">{tt.onBoardingSuccess.content}</p>

            <div className="mt-3">
              {(isIOS || isAndroid) && (
                <a
                  href="https://eatapp.page.link/home"
                  className="py-3.5 px-8 mb-4 bg-green rounded-full text-white text-l font-medium inline-block"
                >
                  {tt.onBoardingSuccess.buttonText}
                </a>
              )}

              {!isIOS && !isAndroid && (
                <Link
                  className="py-3.5 px-8 mb-4 bg-green rounded-full text-white text-l font-medium inline-block"
                  to={AppPage}
                >
                  {tt.onBoardingSuccess.buttonText}
                </Link>
              )}
            </div>
          </div>
          <div className="hidden md:block">
            <GatsbyImage
              image={imageData.desktop?.childImageSharp?.gatsbyImageData}
              loading="eager"
              alt="banner image"
              className="w-full"
            />
          </div>
          <div className="block md:hidden self-end">
            <GatsbyImage
              image={imageData.mobile?.childImageSharp?.gatsbyImageData}
              loading="eager"
              alt="banner image"
              className="w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
