import React from "react";

export const DDIcon = ({ className }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      fill="none"
      height="104"
      viewBox="0 0 104 104"
      width="104"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="51.5478" x2="51.5478" y1="104" y2="0">
        <stop offset="0" stopColor="#20452f" />
        <stop offset="1" stopColor="#20452f" stopOpacity="0" />
      </linearGradient>
      <rect fill="#06b05b" height="103.096" rx="22.1565" width="103.096" x=".452174" y=".452174" />
      <rect
        fill="url(#a)"
        fillOpacity=".3"
        height="103.096"
        rx="22.1565"
        width="103.096"
        x=".452174"
        y=".452174"
      />
      <rect
        height="103.096"
        rx="22.1565"
        stroke="#06b05b"
        strokeWidth=".904348"
        width="103.096"
        x=".452174"
        y=".452174"
      />
      <rect
        height="103.096"
        rx="22.1565"
        stroke="#000"
        strokeOpacity=".2"
        strokeWidth=".904348"
        width="103.096"
        x=".452174"
        y=".452174"
      />
      <g fill="#fff">
        <path d="m46.1243 16.2788h-18.0894v8.9687h18.0894c14.9841 0 27.1267 12.0405 27.1267 26.8987 0 14.8581-12.1501 26.8986-27.1267 26.8986h-18.0894v8.9687h18.0894c19.9738 0 36.1714-16.0614 36.1714-35.8673s-16.1976-35.8674-36.1714-35.8674z" />
        <path d="m64.2063 52.1462c0-9.903-8.0951-17.9375-18.0895-17.9375h-18.0819v35.8674h18.0894c9.9869.0075 18.082-8.027 18.082-17.9299zm-27.1267-8.9687h9.0447c4.9972 0 9.0448 4.0135 9.0448 8.9687s-4.0476 8.9687-9.0448 8.9687h-9.0447z" />
      </g>
    </svg>
  );
};
